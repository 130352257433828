// extracted by mini-css-extract-plugin
export var blocksInRowsWithOneActiveGrey = "payload-module--blocks-in-rows-with-one-active-grey--a6c6d";
export var blocksInRowsWithOneActiveGreyBlocksContainer = "payload-module--blocks-in-rows-with-one-active-grey-blocks-container--d4198";
export var blocksInRowsWithOneActiveGreyInnerWrapper = "payload-module--blocks-in-rows-with-one-active-grey-inner-wrapper--4d0de";
export var blocksInRowsWithOneActiveGreySubtitle = "payload-module--blocks-in-rows-with-one-active-grey-subtitle--ee385";
export var blocksInRowsWithOneActiveGreyTitlesContainer = "payload-module--blocks-in-rows-with-one-active-grey-titles-container--4938d";
export var blocksRedirectingToResourcesInnerWrapper = "payload-module--blocks-redirecting-to-resources-inner-wrapper--38109";
export var blocksRedirectingToResourcesSubtitle = "payload-module--blocks-redirecting-to-resources-subtitle--5aa5c";
export var blocksRedirectingToResourcesTitlesContainer = "payload-module--blocks-redirecting-to-resources-titles-container--51808";
export var borderWidth = "payload-module--border-width--d8edb";
export var coloredBlocksWithRedirectsColumn = "payload-module--colored-blocks-with-redirects-column--bae87";
export var coloredBlocksWithRedirectsContainer = "payload-module--colored-blocks-with-redirects-container--47068";
export var coloredBlocksWithRedirectsInnerWrapper = "payload-module--colored-blocks-with-redirects-inner-wrapper--b1539";
export var coloredBlocksWithRedirectsRightPart = "payload-module--colored-blocks-with-redirects-right-part--7ac83";
export var companyInNumbersSection = "payload-module--company-in-numbers-section--d46b0";
export var companyInNumbersTitlesContainer = "payload-module--company-in-numbers-titles-container--cfed6";
export var contactButtonImageCustomSection = "payload-module--contact-button-image-custom-section--7ffae";
export var contactButtonImageSectionButton = "payload-module--contact-button-image-section-button--db201";
export var contactButtonImageSectionContent = "payload-module--contact-button-image-section-content--9736f";
export var contactButtonImageSectionCustomContainer = "payload-module--contact-button-image-section-custom-container--90d47";
export var contactButtonImageSectionImageContainer = "payload-module--contact-button-image-section-image-container--2b153";
export var contactButtonImageSectionInnerWrapper = "payload-module--contact-button-image-section-inner-wrapper--f4633";
export var contactButtonImageSectionText = "payload-module--contact-button-image-section-text--885a0";
export var header = "payload-module--header--e45d8";
export var headerBorder = "payload-module--header-border--57226";
export var headerTitleAndBorderContainer = "payload-module--header-title-and-border-container--e51a1";
export var opacitySliderInnerWrapper = "payload-module--opacity-slider-inner-wrapper--bc3c7";
export var opacitySliderTitlesContainer = "payload-module--opacity-slider-titles-container--43af2";
export var sectionUnderHeaderButton = "payload-module--section-under-header-button--8cb7b";
export var sectionUnderHeaderContentWrapper = "payload-module--section-under-header-content-wrapper--9fde7";
export var sliderWithClickableBlocks = "payload-module--slider-with-clickable-blocks--d97c8";
export var sliderWithClickableBlocksInnerWrapper = "payload-module--slider-with-clickable-blocks-inner-wrapper--50eaa";
export var testimonialTitle = "payload-module--testimonial-title--0744c";
export var testimonialsInnerWrapper = "payload-module--testimonials-inner-wrapper--5e4e4";