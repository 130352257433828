import React from 'react';

import { paths } from '@src/constants/pathsEnum';
import featuredImage from './images/featured-image.jpg';
import CaptorTherapeutics from '@images/logos-partners/Captor-Therapeutics.svg';
import bannerImage from '@commons/contact-button-image-section/images/image4.png';

import * as styles from './payload.module.scss';

export const SEOProps = {
  title: 'Security Services Company | CodiLime',
  description:
    'A secure network ensures data safety and protects your business. See how we shield networks from cyber threats with our security services.',
  featuredImage: `https://codilime.com${featuredImage}`,
};

export const headerProps = {
  title: <>Security</>,
  titleBorderWidth: styles.borderWidth,
  classNames: {
    header: styles.header,
    border: styles.headerBorder,
    titleAndBorderContainer: styles.headerTitleAndBorderContainer,
  },
};

export const sectionUnderHeaderProps = {
  contactButtonLabel: 'Protect your network',
  textPartOne: (
    <>
      In today&apos;s digital age, network security is more important than ever. With constant cyber attacks and data
      breaches, it&apos;s essential to have a strong security infrastructure in place.
      <br />
      <br />
    </>
  ),
  textPartTwo: (
    <>Your network is only as strong as its weakest point. So let us take care of it with our security services.</>
  ),
  classNames: {
    button: styles.sectionUnderHeaderButton,
    contentWrapper: styles.sectionUnderHeaderContentWrapper,
  },
};

export const blocksInRowsWithOneActiveGreyProps = {
  title: 'Our security expertise',
  subtitle: (
    <>
      Every network has a unique environment with different security threats. We combine multiple layers of defenses to
      provide a network security solution guarding your data and infrastructure. That way, you can avoid extra costs and
      prevent threats such as data breaches, denial of service attacks, and many more.
    </>
  ),
  blocks: [
    {
      title: 'Network security',
      description: (
        <>
          Complex network applications encounter a lot of safety risks: they might lack real-time visibility or control
          over the flow of sensitive data. As a result, they often miss real-time detection of threats, making you
          unaware of potential security incidents. We can develop telemetry and monitoring systems for both cloud-native
          and traditional networks. We also cover all bases: firewalls, VPN encryption or log analysis.
        </>
      ),
    },
    {
      title: 'Application security',
      description: (
        <>
          Modern applications need security solutions that keep up with them. We have a deep understanding of the
          software design and development lifecycle that allows us to integrate necessary security solutions early on in
          the process to avoid vulnerabilities and prevent breaches.
        </>
      ),
    },
  ],
  classNames: {
    section: styles.blocksInRowsWithOneActiveGrey,
    innerWrapper: styles.blocksInRowsWithOneActiveGreyInnerWrapper,
    subtitleStyles: styles.blocksInRowsWithOneActiveGreySubtitle,
    titlesContainer: styles.blocksInRowsWithOneActiveGreyTitlesContainer,
    blocksContainer: styles.blocksInRowsWithOneActiveGreyBlocksContainer,
  },
};

export const contactButtonImageSectionProps = {
  sectionProps: {
    classNames: {
      innerWrapper: styles.contactButtonImageSectionInnerWrapper,
      section: styles.contactButtonImageCustomSection,
      customContainer: styles.contactButtonImageCustomContainer,
    },
  },
  title: 'Shield your network from threats',
  imageAlt: 'Shield your network from threats',
  isH2: true,
  buttonTitle: 'Secure it with us',
  image: bannerImage,
  classNames: {
    customContent: styles.contactButtonImageSectionContent,
    imageContainer: styles.contactButtonImageSectionImageContainer,
    customContainer: styles.contactButtonImageSectionCustomContainer,
    customButton: styles.contactButtonImageSectionButton,
    customText: styles.contactButtonImageSectionText,
  },
};

export const opacityScrolledSliderProps = {
  title: 'Why should you take care of your network security?',
  textBlocks: [
    <>
      Digital assets are <span>protected from cyber threats</span> such as malware, ransomware, viruses, phishing
      attacks, and more.
    </>,
    <>
      <span>Sensitive data is secured</span> from unauthorized access, alteration, or deletion.
    </>,
    <>
      <span>Enhanced network performance</span> by reducing network downtime, improving network speed, and ensuring data
      availability.
    </>,
    <>
      <span>Compliance with regulations</span> according to your industry.
    </>,
    <>
      <span>Reduced risk</span> of cyber attacks, data breaches, and other security incidents.
    </>,
    <>
      <span>Early threat detection</span> via reliable monitoring systems.
    </>,
    <>
      <span>Improved customer trust</span> that their data and information are safe when doing business with your
      company.
    </>,
  ],
  blocksGap: 65,
  showOneBlock: true,
  topOpacity: 0.44,
  shiftDown: 0.3,
  classNames: {
    innerWrapper: styles.opacitySliderInnerWrapper,
    titlesContainer: styles.opacitySliderTitlesContainer,
  },
};

export const testimonialProps = {
  testimonial: {
    quote:
      'Captor Therapeutics covers all aspects of early-stage drug development to deliver therapeutics for life-threatening ' +
      'and life-impairing diseases. We have a lot of sensitive data that needs to be processed and analyzed, which requires ' +
      'the IT operations to be like a well-oiled machine. Partnering with CodiLime ensured their full support for securing ' +
      'our network infrastructure, processing and protecting data, as well as smooth day-to-day operations. ' +
      'We’re highly satisfied with this cooperation, as we can focus on our business and biotechnology.',
    author: <>Anna Pawluk, PhD, MBA - Head of Operations</>,
    logo: CaptorTherapeutics,
    logoAlt: 'Captor Therapeutics',
  },
  classNames: {
    innerWrapper: styles.testimonialsInnerWrapper,
  },
  paragraphTitleStyles: styles.testimonialTitle,
};

export const companyInNumbersProps = {
  id: 'about-codilime',
  title: 'Meet the CodiLime company',
  imgSrc: '',
  classNames: {
    titlesContainer: styles.companyInNumbersTitlesContainer,
    section: styles.companyInNumbersSection,
  },
};

export const sliderWithClickableBlocksProps = {
  sectionProps: {
    id: 'our-approach',
    title: 'How we work',
  },
  imgAlt: 'Models of collaboration with us',
  classNames: {
    section: styles.sliderWithClickableBlocks,
    innerWrapper: styles.sliderWithClickableBlocksInnerWrapper,
  },
};

export const blocksRedirectingToResourcesProps = {
  sectionProps: {
    title: <>Our publications</>,
    subtitle: (
      <>
        Network security is a broad and nuanced topic. Head on to our blog to read about our approach to designing
        custom solutions to ensure your network’s safety.
      </>
    ),
    classNames: {
      innerWrapper: styles.blocksRedirectingToResourcesInnerWrapper,
      titlesContainer: styles.blocksRedirectingToResourcesTitlesContainer,
      subtitleStyles: styles.blocksRedirectingToResourcesSubtitle,
    },
  },
  blocks: [
    {
      text: 'HTTP/3 Protocol - Performance and Security Implications',
      link: '/blog/http3-protocol/',
    },
    {
      text: 'Security standards at CodiLime – how do we keep the data safe?',
      link: '/blog/security-standards-codilime-how-keep-data-safe/',
    },
    {
      text: 'IPSec - what it is and how it works',
      link: '/blog/ipsec-what-is-it-and-how-does-it-work/',
    },
    {
      text: 'Rust vs. C - safety and performance in low-level network programming ',
      link: '/blog/rust-vs-c-safety-and-performance-in-low-level-network-programming/',
    },
  ],
};

export const coloredBlocksWithRedirectsProps = {
  title: 'See more of our services',
  subtitle:
    'Our expertise doesn’t end on security. We have the skills and experience to provide support throughout the whole development lifecycle in various fields.',
  blocks: [
    {
      text: (
        <>
          <span>Network professional</span>
          <span>services</span>
        </>
      ),
      link: paths.SERVICES.NETWORK_PROFESSIONAL_SERVICES,
    },
    {
      text: (
        <>
          <span>R&D services</span>
        </>
      ),
      link: paths.SERVICES.R_D,
    },
    {
      text: (
        <>
          <span>Data engineering</span>
          <span>services</span>
        </>
      ),
      link: paths.SERVICES.DATA_ENGINEERING,
    },
    {
      text: (
        <>
          <span>DevOps services</span>
        </>
      ),
      link: paths.SERVICES.DEVOPS,
    },
  ],
  classNames: {
    innerWrapper: styles.coloredBlocksWithRedirectsInnerWrapper,
    container: styles.coloredBlocksWithRedirectsContainer,
    rightPart: styles.coloredBlocksWithRedirectsRightPart,
    column: styles.coloredBlocksWithRedirectsColumn,
  },
};
